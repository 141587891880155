import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConsoleGuard } from '@unitedhubs/ui';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: './site/site.module#SiteModule',
  },
  {
    path: 'console',
    loadChildren: './console/console.module#ConsoleModule',
    canActivate: [ConsoleGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
