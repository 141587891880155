export const fieldInterpolation = '{field}';

export const validationMessages = {
  alias: 'Use lowercase letters, numbers and hyphens',
  aliasUnavailable: 'This alias is unavailable',
  alpha: 'Use letters and numbers',
  alphanum: 'Use letters, numbers and spaces',
  arrayLength: 'Should be {value} items',
  creditCard: 'Should be a valid credit card',
  date: 'Should be a date',
  default: 'This field is invalid',
  digits: 'Should consist of digits',
  email: 'Should be an email',
  equal: 'Should equal {value}',
  max: 'Should be under {max}',
  maxDate: 'Date should be on or before {value}',
  min: 'Should be over {min}',
  minDate: 'Date should be on or after {value}',
  minlength: 'Should be over {requiredLength} characters',
  naming: 'Use letters, spaces, dashes and apostrophes',
  notEqual: 'Should not equal {value}',
  number: 'Should be a number',
  maxlength: 'Should be under {requiredLength} characters',
  required: `${fieldInterpolation} is required`,
  url: 'Should be a URL',
};
