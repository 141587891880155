import { AbstractField } from '../abstract-field';
import { AutocompleteOption } from '../abstract-autocomplete-field';

export class FormSelect extends AbstractField<string> {
  readonly controlType = 'select';
  options: AutocompleteOption[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
