import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material/material.module';
import { ConsoleLayoutComponent } from './layouts/console-layout/console-layout.component';
import { SiteHeaderComponent } from './layouts/site-header/site-header.component';
import { ContextMenuItemComponent } from './layouts/console-layout/context-menu-item/context-menu-item.component';
import { FindContextDialogComponent } from './layouts/console-layout/find-context-dialog/find-context-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateContextDialogComponent } from './layouts/console-layout/create-context-dialog/create-context-dialog.component';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { FormSelectComponent } from './forms/form-select/form-select.component';
import { FormArrayComponent } from './forms/form-array/form-array.component';
import { FormAutocompleteComponent } from './forms/form-autocomplete/form-autocomplete.component';
import { FormAutocompleteListComponent } from './forms/form-autocomplete-list/form-autocomplete-list.component';

@NgModule({
  declarations: [
    ConsoleLayoutComponent,
    SiteHeaderComponent,
    ContextMenuItemComponent,
    FindContextDialogComponent,
    CreateContextDialogComponent,
    FormInputComponent,
    FormSelectComponent,
    FormArrayComponent,
    FormAutocompleteComponent,
    FormAutocompleteListComponent,
  ],
  entryComponents: [FindContextDialogComponent, CreateContextDialogComponent],
  exports: [
    FlexLayoutModule,
    MaterialModule,
    ConsoleLayoutComponent,
    ContextMenuItemComponent,
    SiteHeaderComponent,
    FormInputComponent,
    FormSelectComponent,
    FormAutocompleteComponent,
    FormAutocompleteListComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
  ],
})
export class UiModule {}
