import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { FirebaseError, BaseError } from '../errors/errors';

@Injectable({
  providedIn: 'root',
})
export class DbService {
  firestore: firebase.firestore.Firestore;

  constructor(public afs: AngularFirestore) {
    this.firestore = afs.firestore;
  }

  // READS
  // ----------------------------------------------------------------------

  collection$(path: string, query?): Observable<any> {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map((action: DocumentChangeAction<any>) => {
            const data: Object = action.payload.doc.data();
            const _id = action.payload.doc.id;
            return { _id, ...data };
          });
        }),
        catchError(err => throwError(new FirebaseError(err)))
      );
  }

  doc$(path: string): Observable<any> {
    this.validateDocPath(path);
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map(action => {
          const data: Object = action.payload.data();
          return data ? { _id: action.payload.id, ...data } : null;
        }),
        catchError(err => throwError(new FirebaseError(err)))
      );
  }

  async data<T>(path: string): Promise<any> {
    this.validateDocPath(path);
    try {
      const doc = this.afs.doc<T>(path);
      return await this.getDocData(doc);
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  async getDocData(doc: AngularFirestoreDocument): Promise<any> {
    const snap = await doc.ref.get();
    return this.formatSnapshotData(snap);
  }

  async getRefData(ref: firebase.firestore.DocumentReference): Promise<any> {
    const snap = await ref.get();
    return this.formatSnapshotData(snap);
  }

  private formatSnapshotData(snap: firebase.firestore.DocumentSnapshot): Object {
    return { _id: snap.id, ...snap.data() };
  }

  // WRITES
  // ----------------------------------------------------------------------

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/
  updatePath<T>(path: string, data: T): Promise<any> {
    delete data['_id'];
    if (this.isCollection(path)) {
      return this.afs.collection<T>(path).add(data);
    } else {
      return this.afs.doc<T>(path).set(data, { merge: true });
    }
  }

  delete(path: string): Promise<any> {
    this.validateDocPath(path);
    return this.afs.doc(path).delete();
  }

  // UTILITY
  // ----------------------------------------------------------------------

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /**
   * @param  {number} lat latitude
   * @param  {number} lng longitude
   *
   * Usage:
   * const geopoint = this.db.getGeopoint(38, -119)
   * return this.db.updatePath('items', { location: geopoint })
   **/
  getGeopoint(lat: number, lng: number) {
    return new firebase.firestore.GeoPoint(lat, lng);
  }

  docExists(doc: AngularFirestoreDocument): Promise<boolean> {
    return doc
      .snapshotChanges()
      .pipe(
        take(1),
        map(action => action.payload.exists)
      )
      .toPromise();
  }

  async getDocId(doc: AngularFirestoreDocument): Promise<string> {
    const snap = await doc.ref.get();
    return snap.id;
  }

  // PRIVATE
  // ----------------------------------------------------------------------

  private isCollection(path: string): boolean {
    const segments = path.split('/').filter(v => v);
    return segments.length % 2 === 0;
  }

  private validateDocPath(path: string): never | void {
    if (this.isCollection(path)) {
      throw new BaseError('path should reference a doc');
    }
  }
}
