export interface AutocompleteOption {
  value: string;
  label: string;
  data?: Object;
}

export abstract class AbstractAutocompleteField {
  readonly addOnEnter?: boolean;
  readonly callback?: Function;
  readonly controlType: string;
  readonly placeholder?: string;
  readonly optionFieldMap?: AutocompleteOption;

  constructor(
    options: {
      addOnEnter?: boolean;
      callback?: Function;
      optionFieldMap?: AutocompleteOption;
      placeholder?: string;
    } = {}
  ) {
    this.addOnEnter = options['addOnEnter'] || false;
    this.callback = options['callback'] || null;
    this.optionFieldMap = options['optionFieldMap'] || null;
    this.placeholder = options['placeholder'] || null;
  }
}
