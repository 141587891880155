export const enum Context {
  Person = 'person',
  Team = 'team',
  Organization = 'organization',
  Community = 'community',
  Event = 'event',
}

export const enum ContextRole {
  Custodian = 'custodian',
  Admin = 'admin',
  Manager = 'manager',
  Moderator = 'moderator',
  Member = 'member',
  Guest = 'guest',
}

export const enum MemberStatus {
  Blocked = 'blocked',
  Invited = 'invited',
  Active = 'active',
  Inactive = 'inactive',
  Left = 'left',
}

export const enum TeamCategory {
  Band = 'band',
  Club = 'club',
  Partnership = 'partnership',
  Unit = 'unit',
}

export const enum OrganizationCategory {
  Charity = 'charity',
  Committee = 'committee',
  Company = 'company',
  Cooperative = 'cooperative',
  Fund = 'fund',
  NonProfit = 'non-profit',
  School = 'school',
  Society = 'society',
  Trust = 'trust',
}

export const enum CommunityCategory {
  Association = 'association',
  Community = 'community',
  Congress = 'congress',
  Council = 'council',
  Federation = 'federation',
  Jurisdiction = 'jurisdiction',
  Union = 'union',
}

// export const enum ProjectStatus {
//   Ongoing = 'ongoing',
//   Onhold = 'onhold',
//   Cancelled = 'cancelled',
//   Completed = 'completed',
// }
