import { OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseClass } from '@unitedhubs/core';
import { HelperText, AbstractField } from './abstract-field';
import { takeUntil } from 'rxjs/operators';
import { FormsService } from './forms.service';

export abstract class AbstractFormFieldComponent extends BaseClass implements OnInit {
  @Input() form: FormGroup;
  @Input() field: AbstractField<any>;

  helperText: HelperText = {
    error: '',
    hint: '',
    classes: '',
  };

  constructor(protected forms: FormsService) {
    super();
  }

  ngOnInit() {
    this.helperText.hint = this.field.hints.pristine || this.field.hints.valid;
    this.form
      .get(this.field.name)
      .statusChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.helperText = <HelperText>(
          this.forms.updateHelperText(this.form, this.field, value)
        );
      });
  }
}
