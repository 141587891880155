import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import * as firebase from 'firebase/app';
import 'firebase/functions';

export class AsyncValidators {
  static aliasAvailable(path: string): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const data = { alias: control.value.toLowerCase(), path };
      const checkAliasExists = firebase.functions().httpsCallable('checkAliasExists');
      try {
        const aliasExists = await checkAliasExists(data);
        return aliasExists.data ? { aliasUnavailable: true } : null;
      } catch (err) {
        console.warn(err);
      }
    };
  }
}
