import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { BaseClass, DbService } from '@unitedhubs/core';
import { FormAutocomplete } from './form-autocomplete';
import { AutocompleteOption } from '../abstract-autocomplete-field';

@Component({
  selector: 'ui-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
})
export class FormAutocompleteComponent extends BaseClass implements OnInit {
  @Input() form: FormGroup;
  @Input() field: FormAutocomplete;

  options$: Observable<AutocompleteOption[]>;

  constructor(private db: DbService) {
    super();
  }

  ngOnInit() {
    if (this.field.callback) {
      this.options$ = this.form.get(this.field.name).valueChanges.pipe(
        filter(val => !!val),
        debounceTime(250),
        switchMap(this.field.callback({ db: this.db })),
        map((options: any[]) =>
          options.map(option => ({
            value: option[this.field.optionFieldMap.value],
            label: option[this.field.optionFieldMap.label],
            data: option,
          }))
        ),
        takeUntil(this.destroy$)
      );
    }
  }
}
