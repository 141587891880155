export const errorCodes = {
  firebase: {
    auth: {
      accountExistsWithDifferentCredential: 'account-exists-with-different-credential',
      invalidCustomToken: 'invalid-custom-token',
      invalidEmail: 'invalid-email',
      networkRequestFailed: 'network-request-failed',
    },
    permissionDenied: 'permission-denied',
  },
  default: 'default',
};
