import { Component, Input } from '@angular/core';

import { FormSelect } from './form-select';
import { AbstractFormFieldComponent } from '../abstract-form-field-component';
import { FormsService } from '../forms.service';

@Component({
  selector: 'ui-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent extends AbstractFormFieldComponent {
  @Input() field: FormSelect;

  constructor(protected forms: FormsService) {
    super(forms);
  }
}
