import { get, last } from 'lodash';

import { errorCodes } from './error-codes';
import { errorMessages } from './error-messages';

function getErrorMessage(propertyPath: string): any {
  return get(errorMessages, propertyPath, errorMessages[errorCodes.default]);
}

function createFirebaseErrorMessagePath(
  firebaseCode: firebase.FirebaseError['code']
): string {
  const split = firebaseCode.split('/');
  if (split.length === 2) {
    return `firebase.${split[0]}.${split[1]}`;
  }
  return `firebase.${split[0]}`;
}

export class BaseError extends Error {
  constructor(...params) {
    super(...params);
    this.name = this.constructor.name;
    // if (Error.captureStackTrace) {
    //   Error.captureStackTrace(this, params[0]);
    // }
  }
}

export class FirebaseError extends BaseError {
  code: string;
  message: string;
  originalError: firebase.FirebaseError;

  constructor(error: firebase.FirebaseError) {
    super(error);
    const messagePath = createFirebaseErrorMessagePath(error.code);
    this.code = last(messagePath.split('.'));
    this.message = getErrorMessage(messagePath);
    this.originalError = error;
  }
}
