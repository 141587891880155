import { AbstractAutocompleteField } from '../abstract-autocomplete-field';

export class FormAutocomplete extends AbstractAutocompleteField {
  readonly controlType = 'autocomplete';
  readonly name: string;

  constructor(options: {} = {}) {
    super(options);
    this.name = options['name'] || null;
  }
}
