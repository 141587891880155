import { ValidationErrors } from '@angular/forms';
import { Validators } from '@unitedhubs/core';
import { AbstractAutocompleteField } from './abstract-autocomplete-field';

export interface FormFields {
  [key: string]:
    | AbstractField<any>
    | { [key: string]: AbstractField<any> }
    | { [key: string]: AbstractField<any> }[];
}

type Field = AbstractField<any> | AbstractAutocompleteField;

export type FormFieldsType = Record<
  string,
  Field | Record<string, Field> | Record<string, Field>[]
>;

export interface FieldHints {
  pristine: string;
  pending: string;
  valid: string;
  invalid: string | ValidationErrors;
}

export interface HelperText {
  error: string;
  hint: string;
  classes: string;
}

const fieldHintsTemplate = {
  pristine: null,
  pending: null,
  valid: null,
  invalid: null,
};

const hintClassTemplate = {
  pristine: null,
  valid: 'valid-field',
  pending: null,
  invalid: null,
};

export abstract class AbstractField<T> {
  value: T;
  name: string;
  key: string;
  label: string;
  disabled: boolean;
  validators: any[];
  asyncValidators: any[];
  required: boolean;
  order: number;
  controlType: string;
  placeholder: string;
  hints: FieldHints;
  classes: FieldHints;
  min: string | number | Date;
  max: string | number | Date;

  constructor(
    options: {
      value?: T;
      name?: string;
      label?: string;
      disabled?: boolean;
      validators?: any[];
      asyncValidators?: any[];
      order?: number;
      controlType?: string;
      placeholder?: string;
      hints?: Partial<FieldHints>;
      classes?: Partial<FieldHints>;
      min?: string | number | Date;
      max?: string | number | Date;
    } = {}
  ) {
    this.value = options.value;
    this.name = options.name || '';
    this.key = options.name;
    this.label = options.label || '';
    this.disabled = options.disabled || false;
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidators || [];
    this.required = this.validators.includes(Validators.required);
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.placeholder = options.placeholder || '';
    this.hints = Object.assign({}, fieldHintsTemplate, options.hints);
    this.classes = Object.assign({}, hintClassTemplate, options.classes);
    this.min = options.min || '';
    this.max = options.max || '';
  }
}
