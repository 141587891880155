import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Context, ContextService } from '@unitedhubs/context';
import { AsyncValidators, DbService, Validators } from '@unitedhubs/core';
import { FormFieldsType, FormInput, FormSelect } from '../../../forms';
import { FormsService } from '../../../forms/forms.service';

@Component({
  selector: 'ui-create-context-dialog',
  templateUrl: './create-context-dialog.component.html',
  styleUrls: ['./create-context-dialog.component.scss'],
})
export class CreateContextDialogComponent {
  contextLabel: string;
  formError: string;
  form: FormGroup;
  fields: FormFieldsType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { context: Context },
    public fb: FormBuilder,
    public db: DbService,
    protected forms: FormsService,
    private context: ContextService,
    private dialogRef: MatDialogRef<CreateContextDialogComponent>
  ) {
    this.contextLabel = this.context.getContextLabel(data.context);
    this.fields = {
      name: new FormInput({
        name: 'name',
        placeholder: `${this.contextLabel} Name`,
        validators: [Validators.required],
      }),
      category: new FormSelect({
        name: 'category',
        placeholder: 'Category',
        options: this.context.getCategoryOptions(this.data.context),
        validators: [Validators.required],
      }),
      isVisible: new FormSelect({
        name: 'isVisible',
        placeholder: 'Visibility',
        options: [{ label: 'Visible', value: true }, { label: 'Hidden', value: false }],
        validators: [Validators.required],
      }),
    };
    this.form = this.forms.buildForm(this.fields);
  }

  async submit() {
    try {
      const contextId = await this.context.create(this.form.value, this.data.context);
      const url = this.context.getConsoleUrl(contextId, this.data.context);
      window.open(url);
    } catch (err) {
      this.formError = err.message;
    }
  }

  closeParentDialog() {
    this.dialogRef.close('closeParentDialog');
  }
}
