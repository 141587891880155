import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@unitedhubs/auth';
import { DbService, FirebaseError } from '@unitedhubs/core';
import { BaseContext } from './base-context';
import { Context, ContextRole } from './constants';
import { ContextMember } from './context-member';
import { AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  organization$: Observable<BaseContext>;

  constructor(
    private auth: AuthService,
    private db: DbService // private user: PersonService
  ) {}

  async create(data: Partial<BaseContext>, context: Context): Promise<string> {
    try {
      // const user = await this.auth.data;
      // const user = this.user.getDoc('profile');
      const contextDoc = await this.db.updatePath<Partial<BaseContext>>(
        'contexts',
        Object.assign(data, {
          context,
          createdAt: this.db.timestamp,
        })
      );
      return this.db.getDocId(contextDoc);

      // const organizationUser = <ContextMember>{
      //   uid: user.uid,
      //   organizationId: contextDoc.id,
      //   createdAt: this.db.timestamp,
      //   isVisibleUser: user.isVisible,
      //   isVisibleContext: data.isVisible,
      //   isCollaborator: false,
      //   role: ContextRole.Custodian,
      // };
      // await this.db.updatePath(
      //   `organizationUsers/${contextDoc.id}_${user.uid}`,
      //   organizationUser
      // );
      // return this.db.getRefData(contextDoc.ref);
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  async update(contextId: string, data: Partial<BaseContext>): Promise<any> {
    try {
      const contextDoc = await this.db.updatePath<Partial<BaseContext>>(
        `contexts/${contextId}`,
        Object.assign(data, {
          updatedAt: this.db.timestamp,
        })
      );
      return contextDoc === undefined ? true : false;
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  getPublicDoc(docName: string, organizationId: string) {
    return this.db.afs.doc<BaseContext>(`contexts/${organizationId}/public/${docName}`);
  }

  getConsoleUrl(contextId: string, context: Context) {
    let tld: any = window.location.hostname.split('.');
    tld = tld[tld.length - 1];
    return `https://unitedhubs.${tld}/console/${context}/${contextId}?referral=${encodeURI(
      window.location.href
    )}`;
  }

  getContextLabel(context: string): string {
    let label = null;
    switch (context) {
      case Context.Team:
        label = 'Team';
        break;
      case Context.Organization:
        label = 'Organization';
        break;
      case Context.Community:
        label = 'Community';
        break;
    }
    return label;
  }

  getCategoryOptions(context: string): Object[] {
    let options;
    switch (context) {
      case Context.Team:
        options = [];
        break;
      case Context.Organization:
        options = [
          { label: 'Cooperative', value: 'cooperative' },
          { label: 'Corporation', value: 'corporation' },
          { label: 'Non-profit', value: 'non-profit' },
        ];
        break;
      case Context.Community:
        options = [
          { label: 'Association', value: 'association' },
          { label: 'Community', value: 'community' },
          { label: 'Congress', value: 'congress' },
          { label: 'Council', value: 'council' },
          { label: 'Federation', value: 'federation' },
        ];
        break;
    }
    return options;
  }
}
