import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, FormArray } from '@angular/forms';
import { startCase } from 'lodash';

import { AbstractField, HelperText } from './abstract-field';
import { validationMessages, fieldInterpolation } from '@unitedhubs/core';
import {
  FormAutocompleteList,
  AutocompleteItem,
} from './form-autocomplete-list/form-autocomplete-list';
import { FormAutocomplete } from './form-autocomplete/form-autocomplete';

const defaultPendingHint = 'checking...';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor() {}

  buildForm(fields: { [key: string]: any }, keyPrefix?: string) {
    const group = {};
    for (const fieldName of Object.keys(fields)) {
      const field = fields[fieldName];
      if (field instanceof FormAutocomplete) {
        // autocomplete field
        group[fieldName] = new FormControl('');
      } else if (field instanceof FormAutocompleteList && field.type) {
        // autocomplete list
        const array = [];
        field.items.forEach((item: AutocompleteItem) => {
          array.push(new FormControl(item.value));
        });
        group[fieldName] = new FormArray(array);
      } else if (field instanceof AbstractField) {
        // form field
        if (keyPrefix) {
          field.key = `${keyPrefix}.${field.name}`;
        }
        group[fieldName] = new FormControl(
          { value: field.value, disabled: field.disabled },
          field.validators,
          field.asyncValidators
        );
      } else if (Array.isArray(field)) {
        // array of groups
        const array = [];
        field.forEach(arrayField => {
          array.push(this.buildForm(arrayField));
        });
        group[fieldName] = new FormArray(array);
      } else if (field instanceof Object) {
        // group
        // TODO: better check
        group[fieldName] = this.buildForm(field, fieldName);
      }
    }
    return new FormGroup(group);
  }

  updateHelperText(form: FormGroup, field, _data?: any) {
    const control = form.get(field.name);
    let error = '';
    let hint = '';
    let classes = '';
    // invalid
    if (control && control.errors && control.dirty && !control.valid) {
      error = this.formatValidationError(field, control.errors);
      classes = field.classes.invalid;
      // pending
    } else if (control.status === 'PENDING') {
      hint = field.hints['pending'] || defaultPendingHint;
      classes = field.classes.pending;
      // valid
    } else if (field.hints['valid'] && control.dirty && control.valid) {
      hint = field.hints['valid'];
      classes = field.classes.valid;
      // pristine
    } else if (control.valid) {
      hint = field.hints['pristine'];
      classes = field.classes.pristine;
    }
    return <HelperText>{ error, hint, classes };
  }

  private formatValidationError(
    field: AbstractField<string>,
    errors: ValidationErrors
  ): string {
    const key = Object.keys(errors)[0];
    let error = errors[key];
    // sometimes the key is nested and appears twice
    if (error[key] !== undefined) {
      error = error[key];
    }
    // return custom error hints if set
    if (field.hints['invalid'] && field.hints['invalid'][key]) {
      return field.hints['invalid'][key];
    }
    let hint = validationMessages[key];
    // return default error hint for undefined keys
    if (hint === undefined) {
      return validationMessages['default'];
    }
    // check if any interpolations are specified
    const interpolations = hint.match(/\{\w+\}/g);
    if (interpolations) {
      for (const interpolation of interpolations) {
        if (interpolation === fieldInterpolation) {
          hint = hint.replace(fieldInterpolation, startCase(field.name));
        } else {
          const match = interpolation.replace(/[\{\}]/g, '');
          hint = hint.replace(interpolation, error[match]);
          if (hint.includes('undefined')) {
            hint = validationMessages['default'];
            break;
          }
        }
      }
    }
    // return hint edited or as is
    return hint;
  }
}
