import { Component, OnInit, Input } from '@angular/core';
import { DbService } from '@unitedhubs/core';
import { Organization, ContextMember } from '@unitedhubs/context';

@Component({
  selector: 'ui-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss'],
})
export class ContextMenuItemComponent implements OnInit {
  @Input() personContext: ContextMember | any;

  itemPromise: Promise<Organization>;

  constructor(private db: DbService) {}

  ngOnInit() {
    if (this.personContext.organizationId !== undefined) {
      this.itemPromise = this.db.data<Organization>(
        `organizations/${this.personContext.organizationId}`
      );
    } else if (this.personContext.communityId !== undefined) {
      this.itemPromise = this.db.data<any>(
        `communities/${this.personContext.communityId}`
      );
    }
  }
}
