import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'ui-form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.scss'],
})
export class FormArrayComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() key: string;
  @Input() fields: Object[];

  ngOnInit() {
    this.fields = this.fields.map((field: FormGroup) => Object.values(field));
  }

  get array() {
    return this.form.get(this.key) as FormArray;
  }

  addGroup() {
    this.fields.push(this.fields[0]);
    const group = <FormGroup>this.array.controls[0];
    const copy = new FormGroup({});
    Object.keys(group.getRawValue()).forEach((key, i) => {
      copy.addControl(
        key,
        new FormControl(
          '',
          this.fields[0][i].validators,
          this.fields[0][i].asyncValidators
        )
      );
    });
    this.array.push(copy);
  }

  removeGroup(index: number) {
    if (this.array.length > 1) {
      this.array.removeAt(index);
      this.fields.splice(index, 1);
    }
  }
}
