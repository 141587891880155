import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

import { Context, ContextService } from '@unitedhubs/context';
import { BaseClass } from '@unitedhubs/core';
import { FormAutocompleteList, FormsService } from '../../../forms';
import { CreateContextDialogComponent } from '../create-context-dialog/create-context-dialog.component';

@Component({
  selector: 'ui-find-context-dialog',
  templateUrl: './find-context-dialog.component.html',
  styleUrls: ['./find-context-dialog.component.scss'],
})
export class FindContextDialogComponent extends BaseClass {
  contextLabel: string;
  form: FormGroup;
  fields = {
    query: new FormAutocompleteList({
      type: 'template',
      placeholder: 'Search for organization...',
      optionFieldMap: { value: 'name', label: 'name' },
      callback: services => query => {
        return services.db.afs
          .collection('organizations', ref =>
            ref
              .where('isVisible', '==', true)
              .orderBy('name')
              .limit(8)
              .startAt(query)
              .endAt(query + '\uf8ff')
          )
          .valueChanges();
      },
    }),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { context: string },
    public dialog: MatDialog,
    private forms: FormsService,
    private context: ContextService,
    private dialogRef: MatDialogRef<FindContextDialogComponent>
  ) {
    super();
    this.contextLabel = this.context.getContextLabel(data.context);
    this.form = this.forms.buildForm(this.fields);
  }

  openCreateContextDialog() {
    const dialogRef = this.dialog.open(CreateContextDialogComponent, {
      panelClass: 'fullscreen-dialog',
      hasBackdrop: false,
      data: { context: this.data.context },
    });
    dialogRef
      .beforeClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === 'closeParentDialog') {
          this.dialogRef.close();
        }
      });
  }
}
