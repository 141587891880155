import {
  AbstractAutocompleteField,
  AutocompleteOption,
} from '../abstract-autocomplete-field';

export interface AutocompleteItem extends AutocompleteOption {
  class?: string;
}

export class FormAutocompleteList extends AbstractAutocompleteField {
  readonly controlType = 'autocomplete-list';
  readonly type = 'chip' || 'list' || 'template';
  items: AutocompleteItem[];

  constructor(options: {} = {}) {
    super(options);
    this.items = options['items'] || [];
    this.type = options['type'] || null;
  }
}
