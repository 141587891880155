import { errorCodes } from './error-codes';

export const errorMessages = {
  firebase: {
    auth: {
      [errorCodes.firebase.auth.accountExistsWithDifferentCredential]:
        'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
      [errorCodes.firebase.auth.invalidCustomToken]: 'The token seems to be invalid',
      [errorCodes.firebase.auth.invalidEmail]: 'The email seems to be invalid',
      [errorCodes.firebase.auth.networkRequestFailed]: 'The network connection was lost',
    },
    [errorCodes.firebase.permissionDenied]:
      "Looks like you don't have the required permissions",
  },
  [errorCodes.default]: 'Something went wrong :/',
};
