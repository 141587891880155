import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
} from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { BaseClass, DbService } from '@unitedhubs/core';
import { AutocompleteItem, FormAutocompleteList } from './form-autocomplete-list';
import { AutocompleteOption } from '../abstract-autocomplete-field';

@Component({
  selector: 'ui-form-autocomplete-list',
  templateUrl: './form-autocomplete-list.component.html',
  styleUrls: ['./form-autocomplete-list.component.scss'],
})
export class FormAutocompleteListComponent extends BaseClass
  implements OnInit, OnDestroy {
  @Input() field: FormAutocompleteList;
  @Input() form: FormGroup;
  @Input() key: string;
  @Input() templateRef?: TemplateRef<any>;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('autocomplete') matAutocomplete: MatAutocomplete;

  query$ = new Subject<string>();
  options$: Observable<AutocompleteOption[]>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(protected db: DbService) {
    super();
  }

  ngOnInit() {
    if (this.field.callback) {
      this.options$ = this.query$.pipe(
        filter(val => !!val),
        debounceTime(250),
        switchMap(this.field.callback({ db: this.db })),
        map((options: any[]) =>
          options.map(option => ({
            value: option[this.field.optionFieldMap.value],
            label: option[this.field.optionFieldMap.label],
            data: option,
          }))
        ),
        takeUntil(this.destroy$)
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.query$.next();
    this.query$.complete();
  }

  get array() {
    return this.form.get(this.key) as FormArray;
  }

  onInput(event: KeyboardEvent): void {
    this.query$.next((<HTMLInputElement>event.target).value.toLowerCase());
  }

  add(event: MatChipInputEvent): void {
    if (this.field.addOnEnter && !this.matAutocomplete.isOpen) {
      this._add(event.value, event.value);
    }
    this.input.nativeElement.value = '';
  }

  removeIndex(index: number): void {
    this._remove(index);
  }

  removeOption(option: AutocompleteOption): void {
    const index = this.field.items.indexOf(option);
    this._remove(index);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._add(event.option.value, event.option.viewValue);
    this.input.nativeElement.value = '';
  }

  private _add(value: string, label: string) {
    const item = <AutocompleteItem>{ value, label };
    this.field.items.push(item);
    this.array.push(new FormControl(item.value));
  }

  private _remove(index) {
    if (index >= 0) {
      this.field.items.splice(index, 1);
      this.array.removeAt(index);
    }
  }
}
