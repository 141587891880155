import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@unitedhubs/auth';
import { DbService, BaseClass } from '@unitedhubs/core';
import { FindContextDialogComponent } from './find-context-dialog/find-context-dialog.component';

@Component({
  selector: 'ui-console-layout',
  templateUrl: './console-layout.component.html',
  styleUrls: ['./console-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsoleLayoutComponent extends BaseClass implements OnInit {
  @Input() fillerNav: Array<string>;
  @Input() appName: string;

  personOrganizations$: Observable<[]>;
  personCommunities$: Observable<[]>;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    private db: DbService
  ) {
    super();
  }

  ngOnInit() {
    const uid = this.auth.uid;
    this.personOrganizations$ = this.db
      .collection$('organizationUsers', ref => ref.where('uid', '==', uid))
      .pipe(catchError(_err => of([])));
    this.personCommunities$ = this.db
      .collection$('communityUsers', ref => ref.where('uid', '==', uid))
      .pipe(catchError(_err => of([])));
  }

  signOut() {
    this.auth.signOut();
  }

  addOrganization() {
    this.dialog.open(FindContextDialogComponent, {
      panelClass: 'fullscreen-dialog',
      hasBackdrop: false,
      data: { context: 'organization' },
    });
  }

  addCommunity() {
    this.dialog.open(FindContextDialogComponent, {
      panelClass: 'fullscreen-dialog',
      hasBackdrop: false,
      data: { context: 'community' },
    });
  }
}
