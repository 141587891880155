export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBUy-hUVg-JSxdM23UhXbYW45A1fWx0Euw',
    authDomain: 'unitedhubs-com.firebaseapp.com',
    databaseURL: 'https://unitedhubs-com.firebaseio.com',
    projectId: 'unitedhubs-com',
    storageBucket: 'unitedhubs-com.appspot.com',
    messagingSenderId: '791173331626',
  },
  algolia: {
    appId: '3B6V3Z0F7M',
    apiKey: '2056d765a431acc713c4059d4b4e229b',
  },
  googleMapsJs: {
    browserKey: 'AIzaSyBUy-hUVg-JSxdM23UhXbYW45A1fWx0Euw',
  },
};
