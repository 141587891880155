import { Injectable, SecurityContext, Sanitizer } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, take, map, tap, concat, catchError } from 'rxjs/operators';
import { last, upperFirst } from 'lodash';

import { FirebaseError, DbService } from '@unitedhubs/core';
import { Person } from './person';
import { LatestLogin } from './system/latest-login';

const storageKeys = {
  person: 'AuthService:person',
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  person$: Observable<Person>;
  cachedPerson$ = new BehaviorSubject<Person>(
    JSON.parse(localStorage.getItem(storageKeys.person))
  );

  constructor(
    private db: DbService,
    private fireAuth: AngularFireAuth,
    private router: Router,
    private sanitizer: Sanitizer,
    private urlSerializer: UrlSerializer
  ) {
    if (location.hostname.split('.')[0] === 'account') {
      this.person$ = this.getAuthState();
    } else {
      this.person$ = this.getCachedPerson().pipe(
        take(1),
        concat(this.getAuthState()) // tslint:disable-line
      );
    }
  }

  private getAuthState() {
    return this.fireAuth.authState.pipe(
      switchMap((firebaseUser: firebase.User) => {
        if (firebaseUser) {
          return this.db.afs.doc<Person>(`people/${firebaseUser.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
      tap((person: Person) => {
        if (person) {
          localStorage.setItem(storageKeys.person, JSON.stringify(person));
          this.cachedPerson$.next(person);
        } else if (person === null) {
          localStorage.removeItem(storageKeys.person);
          this.cachedPerson$.next(null);
        } else {
          this.signOut();
        }
      }),
      catchError(err => {
        console.log('AUTH ERROR:', err);
        return of(null);
      })
    );
  }

  private getCachedPerson(): Observable<Person> {
    return this.cachedPerson$.asObservable();
  }

  // VALIDATION
  // ----------------------------------------------------------------------

  isAuthenticated(): Observable<boolean> {
    return this.person$.pipe(map(person => !!person));
  }

  isRegistered(): Observable<boolean> {
    return this.person$.pipe(map(person => person && person._key !== ''));
  }

  get uid(): string {
    return this.fireAuth.auth.currentUser !== null
      ? this.fireAuth.auth.currentUser.uid
      : null;
  }

  // SESSIONS
  // ----------------------------------------------------------------------

  async createCustomToken(): Promise<string> {
    const createCustomToken = firebase.functions().httpsCallable('createCustomToken');
    try {
      const customToken = await createCustomToken();
      return customToken.data;
    } catch (err) {
      console.warn(err);
    }
  }

  getAccountUrl(route: string = '') {
    const hostname = location.hostname.split('.');
    const domain = last(hostname);
    let path = route;
    if (hostname[0] !== 'account') {
      const tree = this.router.createUrlTree([], {
        queryParams: {
          redirectApp: hostname[0],
          redirectPath: route,
        },
      });
      path = this.urlSerializer.serialize(tree);
    }
    if (!path.startsWith('/')) {
      path = `/${path}`;
    }
    return `https://account.unitedhubs.${domain}${path}`;
  }

  async getRedirectUrl(redirectApp: string, redirectPath?: string): Promise<string> {
    if (!this.isRedirectApp(redirectApp)) {
      return null;
    }
    const domain = 'unitedhubs';
    const topLevelDomain = last(location.host.split('.'));
    const subdomain = redirectApp === domain ? '' : `${redirectApp}.`;
    const customToken = await this.createCustomToken();
    redirectPath = this.formatRedirectPath(redirectPath, customToken);
    return `https://${subdomain}${domain}.${topLevelDomain}${redirectPath}`;
  }

  isRedirectApp(app: string): boolean {
    return ['unitedhubs', 'discussions', 'tasks'].includes(app);
  }

  async signInWithCustomToken(customToken: string): Promise<boolean> {
    try {
      await this.fireAuth.auth.signInWithCustomToken(customToken);
      return true;
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  private formatRedirectPath(path: string, customToken: string): string {
    if (!path) {
      return '';
    }
    path = this.sanitizer.sanitize(SecurityContext.URL, path);
    if (path.startsWith('unsafe:')) {
      path = '';
    } else if (!path.startsWith('/')) {
      path = `/${path}`;
    }
    return `${path}?token=${encodeURI(customToken)}`;
  }

  // AUTHENTICATION
  // ----------------------------------------------------------------------

  async emailLinkLogin(email: string, url: string): Promise<void> {
    try {
      const result = await this.fireAuth.auth.signInWithEmailLink(email, url);
      // if (result.additionalUserInfo.isNewUser) {
      //   // add to db
      // }
      this.createOrUpdate(result.user);
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  loginWithFacebook(): Promise<void> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  loginWithGoogle(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  sendEmailLink(email: string): Promise<void> {
    const actionCodeSettings = { url: window.location.href, handleCodeInApp: true };
    try {
      return this.fireAuth.auth.sendSignInLinkToEmail(email, actionCodeSettings);
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  async signOut(route: string = '/'): Promise<void> {
    localStorage.removeItem(storageKeys.person);
    this.cachedPerson$.next(null);
    await this.fireAuth.auth.signOut();
    this.router.navigateByUrl(route);
  }

  verifyEmailLinkRedirect(url: string): boolean {
    try {
      return this.fireAuth.auth.isSignInWithEmailLink(url);
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  private async createOrUpdate(firebaseUser: firebase.User): Promise<void> {
    const personPath = `people/${firebaseUser.uid}`;
    const personDoc = this.db.afs.doc<Person>(personPath);
    if (!(await this.db.docExists(personDoc))) {
      // create
      await this.preregisterPerson(personDoc, firebaseUser);
      this.router.navigate(['/register'], { queryParamsHandling: 'preserve' });
    } else {
      // update
      const loginDoc = this.db.afs.doc<LatestLogin>(`latestLogins/${firebaseUser.uid}`);
      await loginDoc.update({
        loginAt: this.db.timestamp,
      });
      this.router.navigate(['/console'], { queryParamsHandling: 'preserve' });
    }
  }

  private async oAuthLogin(provider: firebase.auth.AuthProvider): Promise<void> {
    // return this.fireAuth.auth.signInWithPopup(provider).then(credential => {
    //   this.createOrUpdate(credential.user);
    // });
    const credential = await this.fireAuth.auth.signInWithPopup(provider);
    await this.createOrUpdate(credential.user);
  }

  // REGISTRATION
  // ----------------------------------------------------------------------

  async registerPerson(person: Person): Promise<void> {
    try {
      await this.db.updatePath<Person>(`people/${person.uid}`, person);
      this.router.navigate(['/console'], { queryParamsHandling: 'preserve' });
    } catch (err) {
      throw new FirebaseError(err);
    }
  }

  private async preregisterPerson(
    personDoc: AngularFirestoreDocument,
    firebaseUser: firebase.User
  ): Promise<void> {
    const name = firebaseUser.displayName
      ? firebaseUser.displayName.split(' ')
      : ['', ''];
    const person: Person = {
      uid: firebaseUser.uid,
      _key: '',
      email: firebaseUser.email,
      firstName: upperFirst(name[0]).trim(),
      lastName: upperFirst(name[1]).trim(),
    };
    return personDoc.set(person);
  }

  // ACCOUNT LINKING
  // ----------------------------------------------------------------------

  private async oAuthLink(provider: firebase.auth.AuthProvider): Promise<void> {
    try {
      const result = await this.fireAuth.auth.currentUser.linkWithPopup(provider);
      console.log('linked:', result.user);
    } catch (err) {
      console.log('link err:', err);
    }
  }
}
