import { Component, Input } from '@angular/core';

import { FormsService } from '../forms.service';
import { AbstractFormFieldComponent } from '../abstract-form-field-component';
import { FormInput } from './form-input';

@Component({
  selector: 'ui-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent extends AbstractFormFieldComponent {
  @Input() field: FormInput;

  constructor(protected forms: FormsService) {
    super(forms);
  }
}
