import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '@unitedhubs/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ui-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit {
  @Input() appName: string;
  isRegistered: Observable<boolean>;
  loginUrl: string;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.isRegistered = this.auth.isRegistered().pipe(
      tap(isRegistered => {
        if (!isRegistered) {
          this.loginUrl = this.auth.getAccountUrl('/login');
        }
      })
    );
  }
}
