import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { tap, switchMap, catchError, map } from 'rxjs/operators';

import { errorCodes } from '@unitedhubs/core';
import { AuthService } from '@unitedhubs/auth';

@Injectable({ providedIn: 'root' })
export class ConsoleGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isRegistered().pipe(
      switchMap(isRegistered => {
        if (!isRegistered) {
          const token = route.queryParamMap.get('token');
          if (token) {
            return from(this.auth.signInWithCustomToken(token)).pipe(
              catchError(err => of(err.code))
            );
          }
        }
        return of(isRegistered);
      }),
      tap(isRegistered => {
        if (isRegistered === errorCodes.firebase.auth.invalidCustomToken) {
          this.router.navigate(['/']);
        } else if (!isRegistered) {
          const url = this.auth.getAccountUrl(state.url);
          window.location.assign(url);
        }
      }),
      map(isRegistered => {
        if (isRegistered === errorCodes.firebase.auth.invalidCustomToken) {
          return false;
        }
        return !!isRegistered;
      })
    );
  }
}
