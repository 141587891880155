import { Injectable, OnInit } from '@angular/core';
import { FirebaseError, DbService } from '@unitedhubs/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PersonService implements OnInit {
  uid: string;

  constructor(private auth: AuthService, private db: DbService) {}

  ngOnInit() {
    this.uid = this.auth.uid;
  }

  getDoc(docName: string, uid?: string): Observable<any> {
    if (uid) {
      return this.db.doc$(`people/${uid}/public/${docName}`);
    }
    return this.db.doc$(`people/${this.uid}/private/${docName}`);
  }
}
