import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  Validators as AngularValidators,
} from '@angular/forms';
import { CustomValidators as NgxCustomValidators } from 'ngx-custom-validators';

export const Validators = Object.assign(AngularValidators, NgxCustomValidators, {
  alias: (control: AbstractControl): ValidationErrors => {
    const match = /^[a-z0-9\-]+$/g.test(control.value);
    return !match ? { alias: { value: control.value } } : null;
  },
  alpha: (control: AbstractControl): ValidationErrors => {
    const match = /^[A-Za-z\s]+$/g.test(control.value);
    return !match ? { alpha: { value: control.value } } : null;
  },
  alphanum: (control: AbstractControl): ValidationErrors => {
    const match = /^[0-9A-Za-z\s]+$/g.test(control.value);
    return !match ? { alphanum: { value: control.value } } : null;
  },
  ascii: (control: AbstractControl): ValidationErrors => {
    const match = /^[\x00-\x7F]+$/g.test(control.value);
    return !match ? { ascii: { value: control.value } } : null;
  },
  hex: (control: AbstractControl): ValidationErrors => {
    const match = /^[0-9A-Fa-f]+$/g.test(control.value);
    return !match ? { hex: { value: control.value } } : null;
  },
  names: (control: AbstractControl): ValidationErrors => {
    const match = /^[A-Za-z\s'-]+$/g.test(control.value);
    return !match ? { names: { value: control.value } } : null;
  },
  notPattern(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = regex.test(control.value);
      return forbidden ? { notPattern: { value: control.value } } : null;
    };
  },
});
