import { AbstractField } from '../abstract-field';

export class FormInput extends AbstractField<string> {
  readonly controlType = 'input';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
